<template>
  <div class="layout-container">
    <header class="page-header bg-primary">
      <div class="logo-container">
        <img :src="Logo" alt="Start Waves" class="logo" >
      </div>
      <span class="page-title">Marketcap</span>
      <button class="menu-toggle-btn" @click="toggleMenu">
        <i class="fa fa-bars fa-2x"></i>
      </button>
      <div class="menu" :class="{ 'menu-open': isMenuOpen }">
        <router-link to="/" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          Home
        </router-link>
        <router-link to="/about-us" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          About
        </router-link>
        <router-link to="/contact" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          Contact
        </router-link>
        <a href="https://wallet.startwaves.co/" class="menu-item" target="_blank" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          <b>Wallet/Billetera</b>
        </a>
        <a href="https://drive.google.com/file/d/1nhakPxt7nLvQRU87Myag1kJ8QXHAP6f1/view" class="menu-item" target="_blank" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
          <b>WhitePaper 3.5</b>
        </a>
        <!-- Agrega más opciones según sea necesario -->
      </div>
      <button class="back-btn" v-if="currentPage === 'infoview'" @click="$router.push({ path: '/' })">
        <i class="fa fa-angle-left fa-2x"></i>
      </button>
    </header>
    <!-- Resto del código -->
    <div class="page-container">
      <transition name="fade" mode="out-in">
        <keep-alive include="dashboard">
          <router-view />
        </keep-alive>
      </transition>
    </div>
    <footer class="page-footer bg-primary">
      <div class="footer-content">

        <div class="copyright">
          <span>&copy; 2024 Start Waves. All rights reserved.</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import Logo from '@/assets/sw_logo_svg_3.svg';

export default {
  name: 'LayoutPage',
  data() {
    return {
      currentPage: 'dashboard',
      isMenuOpen: false,
      Logo: Logo,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler: function (page) {
        this.currentPage = page.name;
        this.closeMenu();
      },
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>

<style>
/* Agrega estilos según tus necesidades */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-toggle-btn {
  background-color: transparent; /* Fondo transparente */
  border: none; /* Elimina el borde */
  cursor: pointer;
  padding: 8px 15px;
  max-height: 80%;
  transition: background-color 0.3s, border-color 0.3s;
  font-size: 14px; /* Cambia este valor para ajustar el tamaño del ícono */
}

.menu-toggle-btn:hover {
  background-color: #ddd;
  border-color: #ddd;
}


.logo-container {
  display: flex;
  align-items: flex-start;
  margin-left: 40px;
}

.logo {
  max-height: 80px; /* Ajusta el tamaño máximo según tu diseño */
  width: 190px;
  margin-right: 10px; /* Ajusta el margen derecho según tus necesidades */
  padding: 5px; /* Ajusta el relleno según tus necesidades */
}

.menu {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1000; /* Asegura que el menú esté en la capa superior */
  transition: opacity 0.3s, transform 0.3s; /* Agrega transiciones */
}

.menu-open {
  display: flex;
  flex-direction: column; /* Muestra el menú en vertical */
  opacity: 1; /* Asegura que el menú sea visible cuando se abre */
  transform: translateY(0); /* Ajusta la animación de apertura */
}

.menu-item {
  padding: 10px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
  display: flex;
  align-items: center; /* Centra el contenido verticalmente */
}

.menu-item:hover {
  background-color: #ddd;
}

.menu-item:not(:last-child) {
  border-bottom: 1px solid #ccc; /* Añade bordes entre elementos del menú */
}

.menu-item .fa {
  margin-right: 10px; /* Espacio entre el icono y el texto */
}

.page-footer {
  background-color: #1976D2;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 5px solid #1565C0; /* Añade borde superior con profundidad */
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2); /* Añade sombra al borde superior */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: right;
}

/* Media query para dispositivos pequeños */
@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Cambia a columna en dispositivos pequeños */
    align-items: center; /* Centra los elementos */
    text-align: center; /* Centra el texto */
  }

  .slogan,
  .copyright {
    margin-bottom: 10px; /* Agrega espacio entre los elementos */
  }
}

.slogan {
  font-size: 18px;
  font-weight: bold;
}

.copyright {
  font-size: 14px;
}


/* Resto de estilos */
</style>
